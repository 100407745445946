<template>
  <v-card>
    <v-row
      class="pa-3"
      no-gutters
    >
      <v-col
        v-for="(field, index) in user_fields"
        :key="index"
        class="px-2"
        cols="12"
      >
        <v-text-field
          outlined
          dense
          :label="field"
          :value="payload[field]"
          @change="onFieldChange($event, field)"
        />
      </v-col>
      <v-col class="sm12 px-2">
        {{ payload.customClaims }}
      </v-col>
      <v-col class="sm12 px-2">
        <v-checkbox
          label="Disable account"
          color="red"
          :value="payload.disabled"
          @change="onFieldChange($event, 'disabled')"
        />
      </v-col>
      <v-col class="xm12 px-2">
        <v-btn @click="onSaveClick">
          save
        </v-btn>
      </v-col>
    </v-row>
    DEBUG <br>
    {{ currentUser }}
  </v-card>
</template>
<script>
// import { mapActions } from 'vuex';

export default {
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      user_fields: ['uid', 'email', 'phoneNumber', 'displayName', 'password'],
      currentUser: {},
    };
  },
  methods: {
    // ...mapActions('auth', ['patchAuthUser']),
    onFieldChange(ev, field) {
      this.$set(this.currentUser, field, ev);
    },
    onSaveClick() {
    //   this.patchAuthUser({
    //     id: this.payload.uid,
    //     data: this.currentUser,
    //   });
    },
  },
};
</script>
